export const brokerParams = {
  AliceBlue: {
    required: ["userId", "apiKey", "accessToken"],
    description: "Required parameters: userId, apiKey, accessToken"
  },
  AngelOne: {
    required: ["apiKey", "accessToken"], // jwtToken = accessToken
    description: "Required parameters: apiKey, accessToken"
  },
  Dhan: {
    required: ["clientId", "accessToken"],
    description: "Required parameters: clientId, accessToken"
  },
  HdfcSecurities: {
    required: ["apiKey", "accessToken"],
    description: "Required parameters: apiKey, accessToken"
  },
  IIFL: {
    required: ["clientCode"],
    description: "Required parameter: clientCode"
  },
  ICICI: {
    required: ["apiKey", "secretKey", "accessToken"],
    description: "Required parameters: apiKey, secretKey, accessToken"
  },
  Kotak: {
    required: ["consumerSecret", "consumerKey", "accessToken", "viewToken", "sid", "serverId"],
    description: "Required parameters: consumerSecret, consumerKey, accessToken, viewToken, sid, serverId. For Login: MobileNumber, pan, userId, userPassword"
  },
  Upstox: {
    required: ["apiKey", "apiSecret", "accessToken"],
    description: "Required parameters: apiKey, apiSecret, accessToken"
  },
  Zerodha: {
    required: ["apiKey", "accessToken"],
    description: "Required parameters: apiKey, accessToken"
  }
};

export const commonHeaders = {
  'aq-api-key': '12345',
  'aq-signature': 'f0e18e59b1921529c3856ddb1e6b799d67f05e9aeea37aec82a366d6cfd4ae18',
  'Content-Type': 'application/json'
};

export const commonText = {
  requestBodyIntro: "Please provide the required parameters as per the request body below in broker requests."
};